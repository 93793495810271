import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import FeatureSectionCenterNoimage from '../components/FeatureSectionCenterNoimage/FeatureSectionCenterNoimage';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Slideshow from '../components/Slideshow2/Slideshow';

const Index = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <Slideshow
          title="肉のながおか"
          subtitle="厳選した国産和牛とWineを堪能出来る赤煉瓦造りの大人の隠れ家"
          images={[
            { id: 2, src: '/nagaoka2023/image/top2.png', alt: 'Image 2' },
            { id: 3, src: '/nagaoka2024/t101.jpg', alt: 'Image 3' },
            { id: 1, src: '/nagaoka2024/t104.jpg', alt: 'Image 1' },
          ]}
        />
        <FeatureSectionCenterNoimage
          description={`肉のながおかは

        代表が厳選した国産和牛を活かした料理

        高級感あふれるワインを堪能できる

        オトナの隠れ家です`}
        />

        <div className="wrapper-card">
          <div className="card card-1">
            <Link to="/about">
              <div className="card-wrapper">
                <StaticImage
                  formats={['auto', 'webp']}
                  src="../../static/nagaoka2023/image/top_aboutus.png"
                  width={460}
                  height={270}
                  quality={95}
                  alt="top_aboutus"
                />
                <div className="card-title">
                  ABOUT US<div className="card-subtitle">当店について</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="card card-2">
            <Link to="/greeting">
              <div className="card-wrapper">
                <StaticImage
                  formats={['auto', 'webp']}
                  src="../../static/nagaoka2023/image/top_greeting.png"
                  width={460}
                  height={270}
                  quality={95}
                  alt="top_greeting"
                />
                <div className="card-title">
                  GREETING<div className="card-subtitle">代表挨拶</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="card card-3">
            <Link to="/access">
              <div className="card-wrapper">
                <StaticImage
                  formats={['auto', 'webp']}
                  src="../../static/nagaoka2024/t105.jpg"
                  width={460}
                  height={270}
                  quality={95}
                  alt="top_access"
                />
                <div className="card-title">
                  ACCESS<div className="card-subtitle">営業時間・アクセス</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="card card-4">
            <Link to="/menu">
              <div className="card-wrapper">
                <StaticImage
                  formats={['auto', 'webp']}
                  src="../../static/nagaoka2024/n108.jpg"
                  width={460}
                  height={270}
                  quality={95}
                  alt="top_menu"
                />
                <div className="card-title">
                  MENU<div className="card-subtitle">メニュー</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="card">
            <Link to="/menu#ドッグフード">
              <div className="card-wrapper">
                <StaticImage
                  formats={['auto', 'webp']}
                  src="../../static/nagaoka2024/teddy-top1.jpg"
                  width={460}
                  height={270}
                  quality={95}
                  alt="top_dogfood"
                />
                <div className="card-title">
                  DOG FOOD<div className="card-subtitle">ドッグフード</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="card">
            <Link to="/menu#ドレッシング">
              <div className="card-wrapper">
                <StaticImage
                  formats={['auto', 'webp']}
                  src="../../static/nagaoka2024/n101.jpg"
                  width={460}
                  height={270}
                  quality={95}
                  alt="top_lunchbox_dressing"
                />
                <div className="card-title">
                  LUNCH BOX&DRESSING
                  <div className="card-subtitle">お弁当・ドレッシング</div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Index;

export const Head = () => <Seo />;
